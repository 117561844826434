import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/SEO"
import BlogItem from "../components/BlogItem"
import styled from "styled-components"
import Banner from "../components/Banner"
import Layout from "../components/Layout"
import { Trans } from "react-i18next"

const FeaturedItems = styled.h4`
  font-size: var(--h5);
  color: var(--primaryColor);
`

const HomePage = ({ data }) => {
	const { BlogPostQuery, site } = data;

	return (
		<>
			<Seo />
			<Layout>
				<Banner
					content={site.siteMetadata.title}
				/>
				<FeaturedItems>
					{BlogPostQuery.totalCount} <Trans>Featured Posts</Trans>
				</FeaturedItems>
				{BlogPostQuery.edges.map(({ node }, index) => (
					<BlogItem key={index} nodeObj={node} index={index} />
				))}
			</Layout>
		</>
	)
}

export default HomePage

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}

		site {
			siteMetadata {
				title
			}
		}

		BlogPostQuery: allMarkdownRemark(
			sort: { fields: [frontmatter___date], order: DESC }
			limit: 3
		) {
			totalCount
			edges {
				node {
					frontmatter {
						title
						date(formatString: "MMMM DD, YY")
						slug
						featuredImage {
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: TRACED_SVG
									formats: [AUTO, WEBP]
								)
							}
						}
						featuredImageAlt
					}
					excerpt
				}
			}
		}
	}
`;
